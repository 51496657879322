.contact-group {
  display: flex;
  flex-direction: column;
}

.contact-group .offered-by {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  text-align: center;
}

.contact-group .dealer-name {
  font-weight: bold;
}

.contact-group .contact-buttons {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 16px;
}

.contact-group .contact-button-oem {
  width: 100%;
  font-weight: bold;
}

.contact-group .contact-button:nth-child(2),
.contact-group .contact-button-oem:nth-child(2) {
  margin-left: 5px;
}

.contact-group .contact-button-fsbo {
  width: 100%;
  margin-right: 0;
}

.contact-group.show-icons .contact-button:before {
  display: inline-block;
  content: "";
  height: 14px;
  width: 19px;
  background: url(./Email.svg) no-repeat;
  margin-right: 5px;
}

.contact-group .phone-number {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 90px;
  min-width: fit-content;
  color: var(--bt-color-primary);
}

.contact-group .hide {
  display: none;
}

@media screen and (min-width: 970px) {
  .modal-carousel .contact-group .contact-buttons {
    margin-top: 3px;
  }
}

@media (max-width: 330px) {
  .contact-group .contact-buttons {
    column-gap: 8px;
  }
}
