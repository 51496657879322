/* Mobile First */
.service-page {
  background-color: white;
  padding-bottom: 32px;
}

.service-content {
  padding: 0 16px;
}

.service-content p > a {
  color: var(--bt-color-secondary_action-main);
}

.service-content > .breadcrumb {
  padding: 12px 0;
}

.service-content > .main-section {
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  margin-bottom: 32px;
  align-items: center;
}

.service-content > .accordion {
  margin-bottom: 32px;
}

.service-content  > .main-section > .card {
  margin: 0;
}

.service-content  > .main-section > .card img.logo {
  width: 100%;
  max-width: 150px;
}

.service-content > .main-section > .service-info {
  margin-bottom: 14px;
}

.service-content > .main-section > .service-info > h1 {
  margin-top: 0;
  margin-bottom: 12px;
  color: #0F4D77;
  font-size: 22px;
}

.service-content > .main-section > .service-info > p {
  margin-top: 12px;
  color: #444444;
  font-size: 18px;
  line-height: 25px;
  margin-left: 0;
}

.accordion__button:after {
  min-height: 25.7px;
  min-width: 25.7px;
}

/* Accordion styles */

.accordion {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 16px;
  line-height: 22px;
}

.accordion__button[aria-expanded='true'] {
  border-left: 3px solid var(--bt-color-secondary_action-main);
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
 }

.accordion__button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:after {
  cursor: pointer;
  content: '';
  margin: 20px 30px;
  border-radius: 50%;
  height: 25.7px;
  width: 25.7px;
  border: 1px solid #DEE2E3;
  background: url(../../../icons/DownArrow.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  cursor: pointer;
}

.accordion__button:focus {
  outline: none;
}

.accordion__button[aria-expanded='true'] {
  background: rgba(234, 234, 234, 0.2);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  background: var(--bt-color-secondary_action-main) url(  ../../../icons/DownArrowWhite.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  transform: rotate(180deg);
}

.accordion__panel {
  padding-bottom: 20px;
  padding-right: 70px;
  padding-left: 40px;
  color: #666666;
  background: rgba(234, 234, 234, 0.2);
  border-left: 3px solid var(--bt-color-secondary_action-main);
  animation: fadein 0.35s ease-in;
}

.accordion__panel > p {
  margin-top: 0;
  white-space: pre-line;
}

.accordion__panel > ol {
  margin-top: 0;
}

.accordion__panel > ol strong {
  font-weight: bold;
  color: #000;
}

.accordion__title {
  padding: 18px 10px;
  margin: 0;
  color: #0F4D77;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

/* Accordion styles end */


.accordion__panel {
  padding-left: 10px;
  padding-right: 10px;
}

.question {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.question:first-child {
  margin-top: 0px;
}

.quote-btn {
  display: flex;
  justify-content: center;
}

.quote-btn > a {
  font-size: 18px;
  padding: 17px 68px;
  height: 15px;
  box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.75);
}

.box-ad {
  margin-bottom: 30px;
}

/* Desktop */
@media screen and (min-width: 992px) {
  .service-page {
    padding-bottom: 43px;
  }

  .service-content {
    padding: 0 24px;
    max-width: 1389px;
    margin: 0 auto;
  }

  .service-content > .breadcrumb {
    padding: 16px 0 20px 0;
  }

  .service-content > .main-section  {
    flex-direction: row;
    margin-bottom: 45px;
    align-items: normal;
  }

  .service-content > .main-section.dfp {
    margin-bottom: 30px;
  }

  .service-content > .main-section.dfp {
    margin-bottom: 30px;
  }

  .service-content  > .main-section > .card {
    width: 288px;
    margin-right: 16px;
  }

  .service-content > .main-section > .service-info {
    display: block;
  }

  .service-content > .accordion {
    margin-bottom: 50px;
  }

  .accordion__title {
    padding: 20px 32px;
  }

  .accordion__panel {
    padding-left: 38px;
    padding-right: 70px;
  }

  .quote-btn {
    justify-content: left;
  }
}
