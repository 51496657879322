.sbp-email-lead-modal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(4px);
  z-index: 50;
}

.sbp-email-lead-modal .email-lead-form {
  position: relative;
}

.message-modal {
  max-width: 80%;
  border-radius: 10px;
  padding: 10px;
  background: white; 
  z-index: 50;
  position: relative;
}

/* we should probably convert this into a component  */
.success-alert, .error-alert {
  border: 1px solid #BDC3C7;
  border-radius: 5px;
  display: flex;
}

.success-alert::before {
  background-color: #5CB85C;
  background-image: url(../../../icons/Check.svg);
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}

.message-box {
  background-color: #FFF;
  border-radius: 5px;
  margin: 0;
  padding: 0 18px 0;
  width: 100%;
}

.message-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.message-header > p{
  font: bold;
}


.close-modal{
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  background: url('//boattrader.com/static/img/icons/modal-close-button.svg') no-repeat 50%;
  padding: 12px;
  border: 0;
  filter: brightness(0);
  width: auto;
  height: auto;
}