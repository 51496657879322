.resourceContainer {
  padding: 0 15px;
}

.resourceContainer * {
  font-size: 15px;
  line-height: 16x;
  color: #333;
  height: 100%;
}

.resourceContainer a {
  color: var(--bt-color-secondary_action-main);
  font-size: 12px;
  text-decoration: none;
}

h1.hdrA100 {
  background: url(//boattrader.com/static/img/tol-design/btol/dotted-sep.gif?1178546048) 0 bottom repeat-x;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  margin: 10px 0 7px 0;
  padding: 0 0 7px 0;
}

.resourceBody {
  padding: 0;
}

p.resourcesTopPad {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 25px;
}

p.resourcesTopPad * {
  font-size:12px;
  line-height:16px;
}

.dealerListTitle {
  margin-top: 12px;
  margin-bottom: 12px;
}

p.resourcesTopPad > *:not(strong) {
  font-weight:normal;
}

.adverTxt {
  text-align: center;
}

@font-face {
  font-family: 'FuturaStd-Condensed';
  src: local('Futura Condensed Medium'), url( '../../../fonts/FuturaStdCondensed.woff' );
}
@font-face {
  font-family: 'FuturaStd-CondensedLight';
  src: local( 'Futura Condensed Light' ),
  url( '../../../fonts/FuturaStdCondensedLight.woff' );
}
@font-face {
  font-family: 'FuturaStd-Light';
  src: local( 'Futura Light' ),
  url( '../../../fonts/FuturaStdLight.woff' );
}

.boat-history h1 {
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  margin: 15px 0;
  padding: 0 0 7px 15px;
  border-bottom: 1px dotted #333;
}

.boat-history .page-content {
  width: 100%;
  padding: 6px 12px;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
}

.boat-history img {
  max-width: 100%;
}

.boat-history text {
  text-align: center;
  margin: 24px auto;
}

.boat-history .description {
  color: #535353;
  text-align: left;
  width: 48%;
  display: inline-table;
  height: 100%;
}

.boat-history .description .tagline {
  font-size: 60px;
  line-height: 1em;
  font-family: "FuturaStd-Condensed", sans-serif;
}

.boat-history .description .tagline em {
  display: block;
  font-size: 47px;
  font-style: normal;
  font-weight: normal;
  font-family: "FuturaStd-CondensedLight", sans-serif;
}

.boat-history .description p {
  font-size: 26px;
  line-height: 1.25em;
  font-family: "FuturaStd-Light", sans-serif;
}

.boat-history .cta {
  width: 40%;
  margin-left: 12px;
  display: inline-table;
  height: 100%;
}

.boat-history .cta span {
  display: block;
  text-align: center;
}

.boat-history .cta a {
  display: block;
  text-align: center;
}

.boat-history .cta .tagline {
  color: #1f4d9b;
  font-size: 47px;
  line-height: 1.25em;
  font-family: "FuturaStd-Condensed", sans-serif;
}

.boat-history .cta .link {
  color: #000;
  width: 370px;
  margin: 20px auto 0;
  padding: 12px 0;
  font-size: 34px;
  font-weight: bold;
  font-family: "FuturaStd-Condensed", sans-serif;
  background-color: #fbd731;
  vertical-align: middle;
  text-decoration: none;
  text-transform: uppercase;
}

